import client from './../apiService';
class ImageService{
    uploadImage(file: any){
        let formdata = new FormData();
        formdata.append('file', file);
        const url = `Notification/UploadImage`;
        return client.post(url, formdata);
    }
}

export default new ImageService();