





























































































































































































import { Component, Prop, Vue, Emit } from "vue-property-decorator";
import Navbar from "@/components/common/Navbar.vue";
import MultiSelect from "@/components/common/Inputs/MultiSelect.vue";
import DataTable from "@/components/common/Tables/DataTable.vue";
import Snackbar from "@/components/common/Snackbar.vue";
import ListItem from "@/components/common/List/ListItem.vue";
import ListItemContent from "@/components/common/List/ListItemContent.vue";
import {
  IEntityMinWithParent,
  IDistributor,
  IGeographyList,
} from "@/interfaces/positioncode";
import { INotificationInput } from "@/interfaces/dmsnotification";
import { ITableHeader } from "@/interfaces/common";
import { NotificationType, StockistType } from "@/interfaces/Enums";
import GeographyService from "@/services/GeographyService";
import DistributorService from "@/services/DistributorService";
import NotificationService from "@/services/NotificationService";
import ImageService from "@/services/ImageService";
@Component({
  components: {
    Navbar,
    MultiSelect,
    DataTable,
    Snackbar,
    ListItem,
    ListItemContent,
  },
})
export default class Create extends Vue {
  private selectedZones: IEntityMinWithParent = {
    id: 0,
    name: "",
  };
  private selectedRegions: Array<IEntityMinWithParent> = [];
  private selectedDistributors: Array<IDistributor> = [];
  private zones: Array<IEntityMinWithParent> = [];
  private regions: Array<IEntityMinWithParent> = [];
  private distributors: Array<IDistributor> = [];
  private valid: boolean = false;
  private title: string = "";
  private text: string = "";
  private link: string = "";
  private imageURL: string = "";
  private image: any = null;
  private type: string = "feed";
  private page: number = 1;
  private pages: number = 0;
  private totalItems: number = 0;
  private message: string = "";
  private snackbar: boolean = false;
  private snackbarColor: string = "success";

  private headers: Array<ITableHeader> = [
    {
      text: "Id",
      value: "erpId",
      sortable: false,
      default: "",
    },
    {
      text: "Name",
      value: "name",
      sortable: false,
      default: "",
    },
    {
      text: "Role",
      value: "stockistType",
      sortable: false,
      default: "",
    },
  ];
  private usersList: Array<IDistributor> = [];

  get formReset(): Vue & { reset: () => void } {
    return this.$refs.form as Vue & { reset: () => void };
  }

  private getRefAsAny(refKey: string) {
    return this.$refs[refKey] as any;
  }

  private zoneChange(sZone: IEntityMinWithParent) {
    this.selectedZones = sZone;
    console.log(`this.selectedZones`, this.selectedZones);
    if (this.selectedZones.id > 0) {
      this.getGeographyByParentIds("Region", this.selectedZones.id);
    } else {
      this.getAllRegions();
    }
    this.getDistributors([]);
  }

  private regionChange(sRegion: Array<any>) {
    this.selectedRegions = sRegion;
    let Ids = this.selectedRegions.map((r) => {
      return r.id;
    });
    this.getDistributors(Ids);
    // this.getRefAsAny("regionSelect").clickItem(this.regions);
  }
  private distributorChange(sDistributor: Array<any>) {
    this.selectedDistributors = sDistributor;
    if (this.selectedDistributors.length > 0) {
      this.usersList = this.selectedDistributors.slice(0, 25);
      this.pages = Math.ceil(this.selectedDistributors.length / 25);
      this.totalItems = this.selectedDistributors.length;
    } else {
      this.usersList = this.distributors;
      this.pages = Math.ceil(this.distributors.length / 25);
      this.totalItems = this.distributors.length;
    }
  }
  public closeSnackbar(value: boolean): void {
    this.snackbar = value;
  }

  public changePage(value: number): void {
    this.page = value;
    this.usersList = this.distributors.slice(
      (value - 1) * 25,
      (value - 1) * 25 + 25
    );
  }

  public async getAllZones(): Promise<Array<IGeographyList>> {
    return new Promise<Array<IGeographyList>>((res) => {
      GeographyService.getCompanyZones()
        .then((response) => {
          this.zones = response.data;
          res(response.data);
        })
        .catch((error) => {
          console.log(`error`, error);
        });
    });
  }

  public async getAllRegions(): Promise<Array<IEntityMinWithParent>> {
    return new Promise<Array<IEntityMinWithParent>>((res) => {
      GeographyService.getAllGeographiesOfType("Region")
        .then((response) => {
          this.regions = response.data;
          res(response.data);
        })
        .catch((error) => {
          console.log(`error`, error);
        });
    });
  }
  public async getDistributors(
    regionIds: Array<number>
  ): Promise<Array<IDistributor>> {
    return new Promise<Array<IDistributor>>((res) => {
      DistributorService.getDistributors(regionIds)
        .then((response) => {
          //   if (regionIds.length < 1) {
          //   }
          this.distributors = response.data;
          this.usersList = response.data.slice(0, 25);
          this.pages = Math.ceil(response.data.length / 25);
          this.totalItems = response.data.length;
          //   console.log(`this.usersList`, this.usersList);
          //   console.log(`this.pages`, this.pages);
          //   console.log(`this.totalItems`, this.totalItems);
          res(response.data);
        })
        .catch((error) => {
          console.log(`error`, error);
        });
    });
  }

  public async getGeographyByParentIds(
    geographyLevel: string,
    parentIds: number
  ): Promise<Array<IEntityMinWithParent>> {
    let geography: Array<number> = [];
    geography.push(parentIds);
    return new Promise<Array<IEntityMinWithParent>>((res) => {
      GeographyService.getGeographyByParentId(geographyLevel, geography)
        .then((response) => {
          this.regions = response.data;
          res(response.data);
          console.log("this.regions :>> ", this.regions);
        })
        .catch((error) => {
          console.log(`error`, error);
        });
    });
  }

  public submit(): void {
    let regionIds = this.selectedRegions.map((r) => {
      return r.id;
    });
    let distributorIds = this.selectedDistributors.map((d) => {
      return d.id;
    });
    console.log(`this.selectedZones.id`, this.selectedZones.id);
    let data: INotificationInput = {
      title: this.title,
      text: this.text,
      link: this.link,
      image: this.imageURL,
      type:
        this.type == "feed" ? NotificationType.Feed : NotificationType.Banner,
      zone:
        this.selectedDistributors.length > 0
          ? undefined
          : this.selectedZones.id,
      regions: this.selectedDistributors.length > 0 ? undefined : regionIds,
      distributors: JSON.stringify(distributorIds),
    };
    NotificationService.saveDMSNotification(data)
      .then((response) => {
        console.log(`response`, response);
        this.snackbarColor = "success";
        this.snackbar = true;
        this.message = "Notification Created Successfully";
        this.formReset.reset();
        this.selectedZones = {
          id: 0,
          name: "",
        };
        this.selectedRegions = [];
        this.selectedDistributors = [];
      })
      .catch((error) => {
        console.log(`error`, error);
      });
  }

  public uploadImage(): void {
    ImageService.uploadImage(this.image)
      .then((response) => {
        console.log(`response`, response);
        this.imageURL = response.data;
        this.snackbarColor = "success";
        this.snackbar = true;
        this.message = "Image uploaded successfully";
      })
      .catch((error) => {
        console.log(`error`, error);
      });
  }

  public primaryEvent(): void {
    this.$router.push("/dmsnotifications/list");
  }

  async mounted() {
    this.getAllRegions();
    this.getDistributors([]);
    this.getAllZones();
  }
}
