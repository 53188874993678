import client from "./../apiService";
import { INotificationInput } from "@/interfaces/dmsnotification";
class NotificationService {
  saveDMSNotification(notification: INotificationInput) {
    const url = `Notification/SaveDMSNotification`;
    return client.post(url, notification, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
  getDMSNotification() {
    const url = `Notification/GetDMSNotification`;
    return client.get(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
  deactivateNotification(id: Number) {
    const url = `Notification/DeactivateNotification?notifId=` + id;
    return client.get(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
}

export default new NotificationService();
